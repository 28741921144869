<template>
    <b-container v-if="entryOrder && entryOrderRows" fluid>
        <b-row v-if="!entryOrder.confirmed" class="my-4">
            <b-col>
                <b-btn variant="danger" class="mr-4" v-b-modal.deleteEntryOrder>
                    Izbriši nalog za knjiženje
                </b-btn>
                <router-link :to="$route.path + '/edit'">
                    <b-btn variant="primary">
                        Uredi nalog za knjiženje
                    </b-btn>
                </router-link>
            </b-col>
        </b-row>

        <b-row class="text-left my-2">
            <b-col>
                <table class="table b-table table-striped table-hover table-bordered text-left align-middle">
                    <thead>
                    <tr>
                        <th colspan="2">
                            <h3 class="m-0 text-center">
                                Nalog za knjiženje - <u>{{entryOrder.document_number}}</u> -
                                <template v-if="entryOrder.type === 0">Kupci/Dobavljači</template>
                                <template v-if="entryOrder.type === 1">Banka - Blagajna</template>
                            </h3>
                        </th>
                    </tr>
                    <tr>
                        <th>{{entryOrder.client.name}}</th>
                        <td><b>IDB: </b>{{entryOrder.client.id_number}}</td>
                    </tr>
                    <tr>
                        <td><b>Grad: </b>{{entryOrder.client.township.name}}</td>
                        <td><b>Nalog za knjizenje br. : </b>{{entryOrder.document_number}}</td>
                    </tr>
                    <tr>
                        <td><b>Adresa: </b>{{entryOrder.client.address}}</td>
                        <td><b>Mjesto i datum knjizenja: </b>{{entryOrder.client.township.name}}, {{entryOrder.document_date | moment("DD.MM.YYYY.")}}</td>
                    </tr>
                    <tr>
                        <td><b>PDV broj: </b>{{entryOrder.client.pdv_number}}</td>
                        <td>
                            <b>Knjige: </b>
                            <template v-if="entryOrder.variant === 0">
                                A
                            </template>
                            <template v-if="entryOrder.variant === 1">
                                B
                            </template>
                            <template v-if="entryOrder.in_kpr_form === 1">
                                , KPR
                            </template>
                        </td>
                    </tr>
                    </thead>
                </table>
            </b-col>
        </b-row>

        <b-row>
            <b-col>
                <roza-table :items="entryOrderRows"
                            :fields="entryOrderFields"
                            :prenosFields="prenosFields"
                            :prenosColspan="6"
                            :per-page="perPage"
                            :current-page="currentPage"
                            :actions="entryOrder.confirmed ? null : actions"
                            @action="action"
                            print-page>
                    <template slot="custom-header">
                        <tr>
                            <th :colspan="entryOrderFields.length">POSLOVNA PROMJENA</th>
                        </tr>
                        <tr>
                            <th colspan="6"></th>
                            <th>DUGUJE</th>
                            <th>POTRAŽUJE</th>
                        </tr>
                    </template>
                    <template slot="associate.name" slot-scope="data">
                        <template v-if="!data.item.associate">
                            {{data.item.description}}
                        </template>
                    </template>
                    <template slot="invoice_number" slot-scope="data">
                        <template v-if="data.item['alt_invoice_number']">
                            Veza: {{data.item.invoice_number}}<br/>
                            {{data.item['alt_invoice_number']}}
                        </template>
                        <template v-else>
                            {{data.item.invoice_number}}
                        </template>
                    </template>
                </roza-table>

                <b-alert v-if="!entryOrder.ravnoteza" variant="danger" show>Upozorenje! Nalog za knjiženje nije u ravnoteži!</b-alert>
                <b-alert v-else variant="info" class="print-hide" show>Nalog za knjiženje je u ravnoteži!</b-alert>
                <b-alert v-if="entryOrder.confirmed" variant="success" class="print-hide" show>Nalog za knjiženje je potvrđen!</b-alert>

                <table v-if="entryOrderRows.current_page === entryOrderRows.last_page"
                       class="table b-table table-striped table-hover table-bordered text-left">
                    <thead>
                    <tr class="align-middle">
                        <th>Sačinio:</th>
                        <td><b>Datum: </b> {{entryOrder.document_date | moment("DD.MM.YYYY.")}}</td>
                    </tr>
                    </thead>
                </table>

                <b-pagination align="right"
                              @input="getRows"
                              :total-rows="entryOrderRows.total"
                              v-model="currentPage"
                              :per-page="perPage">
                </b-pagination>
            </b-col>
        </b-row>

        <b-row v-if="!entryOrder.confirmed" class="d-flex">
            <b-col md="12" class="text-right">
                <b-btn class="pull-left m-2"
                       v-b-modal.modalConfirm
                       variant="success">
                    Potvrdi nalog
                </b-btn>
                <b-btn class="pull-right m-2"
                       v-b-modal.rowActionModal
                       variant="primary">
                    Dodaj zapis
                </b-btn>
            </b-col>
        </b-row>

        <b-modal centered
                 ref="rowActionModal"
                 id="rowActionModal"
                 :title="selectedRow ? 'Uredi zapis' : 'Dodaj zapis'"
                 @hide="resetUpdateModal"
                 hide-footer>
            <b-form v-on:submit.prevent="rowAction" ref="rowActionForm">
                <b-form-group
                        v-if="associates"
                        :label-cols-md="4"
                        label="Kupac/Dobavljač:"
                        label-for="associate_id">
                    <select id="associate_id"
                            name="associate_id"
                            class="form-control form-control-sm"
                            v-model="selectedAssociate">
                        <option value="" selected>Izaberite suradnika:</option>
                        <option v-for="buyer in associates.buyers" :value="buyer.id">
                            {{buyer.code}} {{ buyer.name }}
                        </option>
                        <option v-for="supplier in associates.suppliers" :value="supplier.id">
                            {{supplier.code}} {{ supplier.name }}
                        </option>
                    </select>
                </b-form-group>
                <b-form-group
                        :label-cols-md="4"
                        label="Opis dokumenta:"
                        label-for="description">
                    <b-form-input id="description"
                                  name="description"
                                  type="text"
                                  size="sm"
                                  :value="selectedRow ? selectedRow.description : ''"
                    />
                </b-form-group>
                <b-form-group
                        v-if="kontos"
                        :label-cols-md="4"
                        label="Konto:"
                        label-for="konto">
                    <select v-model="selectedKonto"
                            id="konto"
                            name="konto_id"
                            class="form-control form-control-sm"
                            required>
                        <option value="" selected disabled>Izaberite konto:</option>
                        <option v-for="konto in kontos" :value="konto.id">
                            {{konto.code}} - {{konto.name}}
                        </option>
                    </select>
                </b-form-group>
                <b-form-group
                        :label-cols-md="4"
                        :label="entryOrder.type === 1 && ![18,10].includes(selectedKonto) ? 'Veza Fa/Rn' : 'Broj računa:'"
                        label-for="invoice_number">
                    <b-form-input id="invoice_number"
                                  name="invoice_number"
                                  type="text"
                                  size="sm"
                                  :value="selectedRow ? selectedRow.invoice_number : ''"
                                  required
                    />
                </b-form-group>
                <b-form-group
                        v-if="entryOrder.type === 1 && ![18,10].includes(selectedKonto)"
                        :label-cols-md="4"
                        label="Broj računa:"
                        label-for="alt_invoice_number">
                    <b-form-input id="alt_invoice_number"
                                  name="alt_invoice_number"
                                  type="text"
                                  size="sm"
                                  :value="selectedRow ? selectedRow.alt_invoice_number : ''"
                    />
                </b-form-group>
                <b-form-group
                        :label-cols-md="4"
                        label="Datum računa:"
                        label-for="invoice_date">
                    <date-picker format="DD.MM.YYYY"
                                 v-model="invoice_date"
                                 :lang="$parent.hrv"
                                 :first-day-of-week="1"
                                 class="w-100"
                                 :input-attr="{required: true}"
                                 id="invoice_date">
                    </date-picker>
                </b-form-group>
                <b-form-group
                        :label-cols-md="4">
                    <b-form-radio-group
                            v-model="iznos">
                        <b-form-radio value="debit">Duguje</b-form-radio>
                        <b-form-radio value="credit">Potražuje</b-form-radio>
                    </b-form-radio-group>
                </b-form-group>
                <b-form-group v-if="iznos"
                              :label-cols-md="4"
                              :label="iznos === 'debit' ? 'Duguje' : 'Potražuje'"
                              label-for="iznos">
                    <b-form-input id="iznos"
                                  :name="iznos === 'debit' ? 'debit' : 'credit'"
                                  type="number"
                                  step="0.01"
                                  size="sm"
                                  :value="selectedRow ? parseFloat(selectedRow[iznos].replace(/,/g, '.')) : 0">
                    </b-form-input>
                </b-form-group>
                <b-btn variant="primary" type="submit" class="pull-right">Potvrdi</b-btn>
            </b-form>
        </b-modal>

        <b-modal centered
                 ref="deleteRow" title="Potvrda"
                 @ok="deleteRow(deleteRowId)"
                 @cancel="deleteRowId = 0"
                 ok-variant="danger">
            <p class="my-4">Jeste li sigurni da želite izbrisati red?</p>
        </b-modal>

        <b-modal centered
                 id="modalConfirm"
                 title="Potvrda"
                 :ok-disabled="!entryOrder.ravnoteza"
                 @ok="confirm"
                 ok-variant="success"
                 ok-title="Potvrdi"
                 cancel-title="Poništi">
            <p v-if="entryOrder.ravnoteza" class="my-4">
                Jeste li sigurni da želite potvrditi nalog za knjiženje?
            </p>
            <p v-else class="my-4">
                Upozorenje! Radni nalog nije u ravnoteži!
            </p>
        </b-modal>

        <b-modal centered
                 id="deleteEntryOrder"
                 title="Potvrda"
                 @ok="deleteEntryOrder"
                 ok-variant="danger">
            <p class="my-4">Jeste li sigurni da želite izbrisati ovaj nalog za knjiženje?</p>
        </b-modal>
    </b-container>
</template>

<script>
    export default {
        name: 'ShowEntryOrder',
        data() {
            return {
                entryOrder: null,
                entryOrderRows: null,
                kontos: null,
                selectedKonto: 1,
                iznos: null,
                entryOrderFields: [
                    {
                        key: 'position',
                        label: 'Broj'
                    },
                    {
                        key: 'konto',
                        key2: 'code',
                        label: 'Konto'
                    },
                    {
                        key: 'associate',
                        key2: 'code',
                        label: 'Šifra'
                    },
                    {
                        key: 'associate',
                        key2: 'name',
                        label: 'Kupac/Dobavljač'
                    },
                    {
                        key: 'invoice_date',
                        label: 'Datum računa',
                        date: true
                    },
                    {
                        key: 'invoice_number',
                        label: 'Broj računa'
                    },
                    {
                        key: 'debit',
                        label: 'Iznos',
                        class: 'text-right'
                    },
                    {
                        key: 'credit',
                        label: 'Iznos',
                        class: 'text-right'
                    }
                ],
                prenosFields: [
                    'debitTotal',
                    'creditTotal'
                ],
                actions: {
                    delete: {
                        name: 'Izbriši',
                        icon: 'fa fa-trash'
                    },
                    edit: {
                        name: 'Uredi',
                        icon: 'fa fa-edit'
                    }
                },
                deleteRowId: 0,
                currentPage: 1,
                perPage: 10,
                invoice_date: null,
                selectedAssociate: null,
                selectedRow: null
            }
        },
        computed: {
            associates: function () {
                return this.$store.getters.associates;
            }
        },
        methods: {
            getEntryOrder() {
                axiOsta.get(api.entryOrders + this.$route.params.id).then(response => {
                    if (response.status === 200) {
                        this.entryOrder = response.data;
                    }
                });
            },
            confirm() {
                axiOsta.post(api.entryOrderConfirm, {
                    confirmed: this.entryOrder.id
                }).then(response => {
                    if (response.status === 200) {
                        this.$router.go();
                    }
                })
            },
            getRows() {
                axiOsta.get(api.entryOrders + this.$route.params.id + api.rows, {
                    params: {
                        page: this.currentPage,
                        perPage: this.perPage
                    }
                }).then(response => {
                    if (response.status === 200) {
                        this.entryOrderRows = response.data;
                    }
                })
            },
            rowAction: function (e) {
                if(this.selectedRow) {
                    this.updateRow(e);
                }  else {
                    this.addRow(e);
                }
            },
            addRow: function (e) {
                const formData = new FormData(e.target);
                formData.append('entry_order_id', this.entryOrder.id);
                formData.append('invoice_date', this.$moment(this.invoice_date).format("YYYY-MM-DD"));
                axiOsta.post(api.entryOrderRows, formData).then(response => {
                    if (response.status === 201) {
                        this.getEntryOrder();
                        this.getRows();
                        this.$refs.rowActionModal.hide();
                        this.resetUpdateModal();
                    }
                });
            },
            updateRow: function (e) {
                const formData = new FormData(e.target);
                formData.append('invoice_date', this.$moment(this.invoice_date).format("YYYY-MM-DD"));
                axiOsta.post(api.entryOrderRows + this.selectedRow.id, formData).then(response => {
                    if (response.status === 200) {
                        this.getEntryOrder();
                        this.getRows();
                        this.$refs.rowActionModal.hide();
                        this.resetUpdateModal();
                    }
                });
            },
            deleteRow(id) {
                axiOsta.delete(api.entryOrderRows + id).then(response => {
                    if (response.status === 200) {
                        this.getEntryOrder();
                        this.getRows();
                    }
                });
            },
            deleteModal(id) {
                this.deleteRowId = id;
                this.$refs.deleteRow.show();
            },
            updateModal(item) {
                this.selectedRow = item;
                this.invoice_date = item.invoice_date;
                this.iznos = item.debit !== '0,00' ? 'debit' : 'credit';
                this.selectedKonto = item.konto_id;
                this.selectedAssociate = item.associate_id;
                this.$refs.rowActionModal.show();
            },
            deleteEntryOrder() {
                axiOsta.delete(api.entryOrders + this.entryOrder.id).then(response => {
                    if (response.status === 200) {
                        this.$router.replace('/entry_orders');
                    }
                })
            },
            resetUpdateModal() {
                this.$refs.rowActionForm.reset();
                this.selectedRow = null;
                this.invoice_date = null;
                this.iznos = null;
            },
            action: function (action, item) {
                switch (action) {
                    case 'delete':
                        this.deleteModal(item.id);
                        break;
                    case 'edit':
                        this.updateModal(item);
                        break;
                }
            }
        },
        created() {
            this.getEntryOrder();
            this.getRows();
            axiOsta.get(api.kontos).then(response => {
                if (response.status === 200) {
                    this.kontos = response.data;
                }
            });
        }
    }
</script>